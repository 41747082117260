import PropTypes from 'prop-types';
import { Box, Drawer as CustomDrawer, Grid, Stack, Typography, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useLocales from '../../../../hooks/useLocales';
import { useSelector } from '../../../../redux/store';

const CustomText = styled(Typography)(({ theme }) => ({
  color: '#fff',
  fontWeight: 400,
  lineHeight: 1,
  [theme.breakpoints.up('sm')]: {
    fontSize: 16,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '3.2vw',
  },
}));

const ConfirmDepositDrawer = ({
  confirmDeposit,
  toggleDrawer,
  depositAmount,
  extraAmount,
  onSubmit,
  isSubmitting,
  depositFeePercent,
  calculateDeduction,
}) => {
  const { translate } = useLocales();

  const { memberBalance } = useSelector((x) => x.lookup);
  return (
    <CustomDrawer
      anchor="bottom"
      open={confirmDeposit}
      onClose={() => toggleDrawer(false)}
      sx={{ zIndex: 1300, borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
    >
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          background: 'linear-gradient(180deg,#003370 10%,#111923 40%)',
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          boxShadow: '18px 22px 100px rgba(0,0,0,.55)',
          padding: '20px',
          textAlign: 'center',
        }}
      >
        <CustomText sx={{ fontWeight: 800 }}>{translate('deposit_amount')}</CustomText>
        <Typography
          sx={{ color: 'white', fontWeight: 800, fontSize: { md: '20px', sm: '4.5vw', xs: '4.5vw' } }}
        >{`${memberBalance?.currencyCode} ${depositAmount}`}</Typography>
        {/* <Typography
          sx={{ color: 'white', fontWeight: 800, fontSize: '12px' }}
        >{`( +${memberBalance?.currencyCode} ${extraAmount})`}</Typography> */}

        <Box sx={{ my: '.4rem' }}>
          {/* deposit fee */}
          <Stack direction>
            <CustomText>
              {translate('deposit_fee')}({`${depositFeePercent}%`})
            </CustomText>
            <Box
              sx={{
                flex: 1,
                height: '1px',
                border: '1px solid white',
                borderStyle: 'dashed',
                display: 'block',
                mt: 'auto',
              }}
            />
            <CustomText>{`-${memberBalance?.currencyCode} 
            
            
            ${
              depositFeePercent === 0
                ? 0
                : (depositFeePercent / 100) * parseInt(depositAmount?.toString()?.replace(/,/g, ''), 10)
            }`}</CustomText>
          </Stack>

          {/* bonus */}
          <Stack direction sx={{ mt: '.2rem' }}>
            <CustomText>{translate('bonus')}</CustomText>
            <Box
              sx={{
                flex: 1,
                height: '1px',
                border: '1px solid white',
                borderStyle: 'dashed',
                display: 'block',
                mt: 'auto',
              }}
            />
            <CustomText>{`+${memberBalance?.currencyCode} ${extraAmount}`}</CustomText>
          </Stack>

          <Stack direction sx={{ mt: '.2rem' }}>
            <CustomText sx={{ fontWeight: 800 }}>{translate('total_amount_received')}</CustomText>
            <Box
              sx={{
                flex: 1,
                height: '1px',
                border: '1px solid white',
                borderStyle: 'dashed',
                display: 'block',
                mt: 'auto',
              }}
            />
            <CustomText sx={{ fontWeight: 800 }}>{`+${memberBalance?.currencyCode} ${
              calculateDeduction(depositAmount) + parseFloat(extraAmount)
            }`}</CustomText>
          </Stack>
        </Box>

        <Grid container sx={{ mt: { xl: 0, lg: 0, md: 0, sm: 0, xs: 2 } }}>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Typography
              onClick={() => toggleDrawer(false)}
              sx={{
                color: 'white',
                fontSize: { xl: '1.2rem', lg: '1.2rem', md: '1.2rem', sm: '1.2rem', xs: '.75rem' },
                fontWeight: 800,
                cursor: 'pointer',
              }}
            >
              {translate('cancel')}
            </Typography>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <LoadingButton
              onClick={() => onSubmit()}
              variant={'contained'}
              color="info"
              disabled={isSubmitting}
              loading={isSubmitting}
              sx={{
                color: 'white',
                fontSize: { xl: '1.2rem', lg: '1.2rem', md: '1.2rem', sm: '1.2rem', xs: '.75rem' },
                fontWeight: 800,
              }}
            >
              {translate('confirm_to_pay')}
            </LoadingButton>
          </Grid>
        </Grid>

        {/* <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Stack direction="row" sx={{ alignItems: 'center', mt: '20px' }}>
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="question-circle"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
              style={{ color: 'white' }}
            >
              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
              <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z" />
            </svg>
            <Typography sx={{ fontSize: '14px', color: 'white', fontWeight: 400, ml: '3px' }}>
              Payment Problem?
            </Typography>
            <Typography
              sx={{
                color: '#2283f6',
                fontSize: '14px',
                fontWeight: 400,
                ml: 1,
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              Click for help
            </Typography>
          </Stack>
        </Box> */}
      </div>
    </CustomDrawer>
  );
};

ConfirmDepositDrawer.propTypes = {
  confirmDeposit: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  depositAmount: PropTypes.string,
  extraAmount: PropTypes.string,
  isSubmitting: PropTypes.bool,
};

export default ConfirmDepositDrawer;
