/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import { setSession } from '../utils/jwt';
import conObj from '../utils/connection-assistant';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: !!localStorage.getItem('accessToken'),
  isInitialized: false,
  user: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null,
  userProfile: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, userProfile } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      userProfile,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const payStatus = params.get('status');
        const payOrderId = params.get('orderId');
        const referralCode = params.get('aff');
        const agentCode = params.get('ag');
        const localCode = params.get('lang');

        if (referralCode) {
          localStorage.setItem('referralCode', referralCode);
          localStorage.setItem('referralType', 'aff');
          localStorage.removeItem('visitedRefCode');
        }

        if (agentCode) {
          localStorage.setItem('referralCode', agentCode);
          localStorage.setItem('referralType', 'ag');
          localStorage.removeItem('visitedRefCode');
        }

        if (payStatus) {
          localStorage.setItem('status', payStatus);
        }

        if (payOrderId) {
          localStorage.setItem('orderId', payOrderId);
        }

        if (localCode) {
          localStorage.setItem('localCode', localCode);
        }

        if (accessToken) {
          setSession(accessToken);

          const response = await conObj.post('profile.json');
          const { first_name, username, full_name, last_name, email } = response.data.data.data;
          const _user = { displayName: first_name, username, full_name, last_name, email };

          localStorage.setItem('userInfo', JSON.stringify(_user));

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: _user,
              userProfile: response.data.data.data,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              userProfile: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            userProfile: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (data) => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userInfo');

    const response = await conObj.post('login.json', {
      'user-username': data.username,
      'user-password': data.password,
    });

    const {
      token,
      data: { first_name, username, full_name, last_name, email },
    } = response.data.data;

    const _user = { displayName: first_name, username, full_name, last_name, email };

    localStorage.setItem('userInfo', JSON.stringify(_user));

    if (token) {
      setSession(token);

      dispatch({
        type: 'LOGIN',
        payload: {
          user: _user,
        },
      });
    } else throw new Error(response.data?.data?.msg);
  };

  const register = async ({ username, profileNoHp, email, fullName, password, referralCode, birthday }) => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userInfo');

    const referralType = localStorage.getItem('referralType');

    const _username = referralType === 'ag' ? `${referralCode}${username}` : username;

    const response = await conObj.post('register.json', {
      'user-username': _username,
      'user-password': password,
      'user_profile-no_hp': profileNoHp,
      'user-email': email,
      'user-full_name': fullName,
      'user-referral_code': referralCode,
      'user_profile-birthday': birthday,
    });

    const {
      token,
      data: { first_name },
    } = response.data.data;

    const _user = { displayName: first_name, username: _username, email, full_name: fullName };

    localStorage.setItem('userInfo', JSON.stringify(_user));

    localStorage.removeItem('referralCode');
    localStorage.removeItem('referralType');

    setSession(token);

    dispatch({
      type: 'REGISTER',
      payload: {
        user: _user,
      },
    });
    // window.location.reload();
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
