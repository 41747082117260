// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  // login
  login: `LOGIN`,

  // register
  register: `REGISTER`,

  // home
  game_partners: `Game Partners`,
  responsible_gaming: `Responsible Gaming`,
  supported_bank: `Supported Bank`,
  load_more: `Load More`,

  // game
  choose_your_favourite_x_games: `Choose Your Favourite {{x}} Games`,
  choose_your_favourite_x: `Choose Your Favourite {{x}}`,
  launch: `LAUNCH`,

  // notification
  notifications: `Notifications`,
  you_have_x_unread_messages: `You have {{x}} unread messages`,

  // deposit/withdraw
  drop_or_select_file: `Drop or Select file`,
  drop_files_here: `Drop Files here`,
  bank: `Bank`,
  bank_name: `Bank Name`,
  account_name: `Account Name`,
  account_number: `Account Number`,
  bank_account: `Bank Account`,
  something_went_wrong: `Something went wrong. Please contact support.`,
  deposit_amount_between_x_and_y: `Deposit amount must be between {{x}} and {{y}}`,
  minimum_amount_is_x_y: `Minimum amount is {{x}}{{y}}`,
  payment_method: `Payment Method`,
  mode: `Mode`,
  transfer_to: `Transfer To`,
  select: `Select`,
  account: `Account`,
  amount: `Amount`,
  deposit: `Deposit`,
  withdraw: `Withdraw`,
  amount_no_exceed_balance: `Withdraw Amount should not exceed the available balance`,
  minimum_withdraw_amount_is_x: `Minimum Withdraw Amount is {{x}}`,
  maximum_withdraw_amount_is_x: `Maximum Withdraw Amount is {{x}}`,
  available_balance: `Available Balance`,
  amount_withdrawn: `Amount withdrawn`,
  no_bank_account: `You do not have any account to withdraw to, Add now`,
  add_account: `Add Account`,
  account_name_must_be_4: `Account Name must be greater than 4 in length`,
  please_select_bank: `Please select a Bank Account`,
  account_number_must_be_x: `Account number must be 6-10 in length`,
  minimum_length_is_x: `Minimum length is {{x}}`,
  maximum_length_is_x: `Maximum length is {{x}}`,
  withdraw_information: `Withdraw Information`,
  account_holder_name: `Account Holder Name`,
  name_of_the_account_holder: `Name of the account holder`,
  fill_in_bank_account: `Fill In The Bank Account`,
  please_upload_receipt: `Please upload receipt`,
  deposit_bonus: `Deposit Bonus`,
  bank_transfer: `Bank Transfer`,
  upload_receipt: `Upload Receipt`,
  select_bonus: `Select Bonus`,
  no_promotion: `No Promotion`,

  // transaction history
  transaction_history: `Transaction History`,

  // bet history
  bet_history: `Bet History`,
  game_provider: `Game Provider`,
  bet_count: `Bet Count`,
  bet_amount: `Bet Amount`,
  win_loss: `Win/Loss`,
  rebate: `Rebate`,
  rebates: `Rebates`,
  turnover: `Turnover`,
  game_category: `Game Category`,

  // all
  all: `All`,
  x_is_required: `{{x}} is required`,
  note: `Note`,
  save: `Save`,
  please_select_x: `Please select {{x}}`,
  title: `Title`,
  date: `Date`,
  type: `Type`,
  status: `Status`,
  remark: `Remark`,
  clear: `Clear`,
  date_from: `Date From`,
  date_to: `Date To`,
  category: `Category`,
  search: `Search`,
  new: `New`,
  change_password: `Change Password`,
  logout: `Logout`,
  username: `Username`,
  password: `Password`,
  forgot_password: `Forgot Password`,
  confirm: `CONFIRM`,
  dont_have_account: `Don't have a user account?`,
  contact_us: `Contact Us`,
  game_menu: `Game Menu`,
  bonus: 'Bonus',
  no_data: `No Data`,
  terms_and_condition: 'TERMS & CONDITION',
  game_list: 'GAME LIST',
  play: 'Play',
  games: 'Games',
  casino: 'Casino',
  slots: 'Slots',
  card: 'Card',
  fishing: 'Fishing',
  sport: 'Sport',
  sports: 'Sports',
  bonuses: 'Bonuses',
  account_profile: `Account Profile`,
  email: `Email`,
  full_name: `Full Name`,
  phone_number: `Phone Number`,
  level: `Level`,
  bank_list: `Bank List`,
  in_maintenance: `In Maintenance`,
  birthday: `Birthday`,
  birthday_bonus: `Enter your birthday for a bonus! 🎉🎁`,
  referral_code: `Referral Code`,
  invalid_x: `Invalid {{x}}`,
  extra: `Extra`,
  referral: `Referral`,
  my_referral: `My Referral`,
  my_claim: `My Claim`,
  claim_profit: `CLAIM PROFIT`,
  available_profit: `Available Profit`,
  claimed_profit: `Claimed Profit`,
  all_time_profit: `All Time Profit`,
  referral_note_on_x: `Earn profit every time your friends playing on {{x}}`,
  tier: `Tier`,
  commission: `Commission`,
  referred_member: `Referred Member`,
  profit_earned: `Profit Earned`,
  account_information: `Account Information`,
  invite_friends: `Invite Friends`,
  how_it_work: `How it work?`,
  invite_note: `Get exclusive referral bonuses when your friend signs up using your referral code!`,
  share_via: `Share via`,
  referral_link: `Referral Link`,
  total_profit: `Total Profit`,
  copied: `Copied`,
  nothing_to_claim: `Nothing to claim`,
  claim_history: `Claim History`,
  from: `From`,
  to: `To`,
  choose_date: `Choose Date`,
  select_both_date: `Select both Date From and To`,
  hello: 'Hello',
  my_downline: `My Downline`,
  downline: `Downline`,
  forgot_password_contact: 'Please contact customer support provided below to help you change your password',
  submit: 'Submit',
  current_password: 'Current Password',
  new_password: 'New Password',
  confirm_new_password: 'Confirm New Password',
  already_have_an_account: 'Already have an account?',
  share: 'Share',
  more_info: 'More Info',
  copy_link: 'Copy Link',
  share_to_friend: 'Share To Friend',
  get_bonus: 'Get Bonus',
  copy_your_referral: 'Copy your referral link & send to social media and earn credit from your downline!',
  last_x_digit_bank_ref_number: `Last {{x}} digit of Bank Ref number`,
  bank_ref_number_validation_x: `Bank Ref Number is required and must be {{x}} character length`,
  card_game: 'Card Game',
  e_sports: 'E-Sports',
  instant_game: 'Instant Game',
  sort: 'Sort',
  name: 'Name',
  default: 'Default',
  e_sports_bonus: 'E-Sports Bonus',
  fishing_bonus: 'Fishing Bonus',
  casino_bonus: 'Casino Bonus',
  sports_bonus: 'Sport Bonus',
  slots_bonus: 'Slots Bonus',
  instant_game_bonus: 'Instant Game Bonus',
  card_game_bonus: 'Card Game Bonus',
  all_bonus: 'All Bonus',
  how_to_get_referral_bonus: 'How To Get Referral Friend Bonus?',
  steps_to_claim: 'Steps To Claim Bonus',
  share_your_referral_code:
    '1. Share your referral code to your friends to register account via viber, messenger, telegram, facebook, twitter, or instagram.',
  after_friend_register:
    '2. After friend register with ZK99, both referral and referral member required to make deposit to claim this bonus.',
  refer_a_friend_bonus: '3. Refer friend bonus calculation:',
  turnover_played: 'Turnover played x Commision % = Referral Friend Bonus',
  example: 'Example:',
  turnover_formula_x: 'Turnover (1,000,000) x 0.002% (Commission %) = 2,000{{x}}',
  note_bonus_only: '*Note: Bonus only able to claim on the next day after 12:00am',
  note_bonus_only_x: '*Note: Bonus only able to claim on the next day after {{x}}',
  access_dashboard: '4. Access dashboard to check entitled profit, commision tier, claim history, and downline.',
  click_this_button_to_claim: 'Click this button to claim Referral Friend Bonus',
  commision_tier: 'Commission Tier',
  member_downline: 'Member Downline',
  bonus_tutorial_video: 'Bonus Tutorial Video',
  popularity: 'Popularity',
  no_balance: 'You do not have enough balance to proceed',
  invalid_captcha: 'Invalid Captcha',
  captcha: 'Captcha',
  claim_bonus: 'Claim Bonus',
  claimed: 'Claimed',
  bonus_claimed: 'Bonus Claimed',
  online_banking: 'Online Banking',
  please_enter_email: 'Please enter your email address to proceed with the password reset process',
  please_select_a_bank: 'Please select a bank',
  deposit_from_bank: 'Deposit From Bank',
  from_selected_promotion: 'from selected promotion',
  from_selected_bank: 'from selected bank',
  network_error: 'Network Error',
  we_have_sent_otp: 'We have sent an OTP to your phone number',
  your_otp_expire: 'Your OTP will expire in',
  didnt_receive: "Didn't receive the message?",
  send_again: 'Send Again',
  resend_again: 'Resend again in',
  invalid_verification: 'Invalid Verification Code!',
  otp_expired: 'OTP expired, please reenter your phone number and try again!',
  otp_expired_redirect: 'OTP expired! redirecting to login',
  captcha_check_failed: 'Captcha Check Failed!',
  verify_phonenumber: 'Verify Phone Number',
  send_otp: 'Send OTP',
  success_verification: 'Your Phone Number has been verified! Please fill the form below to finish your registration.',
  dont_show_again: "Don't show again",
  next: 'Next',
  back: 'Back',
  close: 'Close',
  payment_draft:
    'If you confirm you have already paid, please wait for a while as the payment might still be processing',
  go_to_home: 'Go To Home',
  payout: `Payout`,
  get: `Get`,
  deposit_fee: `Deposit Fee`,
  total_amount_received: `Total Amount Received`,
  cancel: `Cancel`,
  deposit_amount: `Deposit Amount`,
  confirm_to_pay: `Confirm To Pay`,
  deposit_method: `Deposit Method`,
  redeem_angpau: `REDEEM ANGPAU`,
  promo_concluded: `Promo Concluded`,
  can_only_claim: `Can only claim bonus between {{x}} to {{y}} Malaysia Time`,
  please_login: `Please Login`,
  terms_n_conditions: `TERMS AND CONDITION`,
  claim_now: `CLAIM NOW`,
  the_activity_x_in: `The Activity {{x}} In`,
  starts: `Starts`,
  ends: `Ends`,
  list_of_winners: `LIST OF WINNERS`,
  days: `Days`,
  hours: `Hours`,
  minutes: `Minutes`,
  seconds: `Seconds`,
};

export default en;
